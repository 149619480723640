import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import MenuIcon from "@material-ui/icons/Menu";

import { AppDrawer, ElevationScroll } from ".";

export default function AppNavBar(props) {

  const [open, setOpen] = React.useState(false);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    //
    <AppBar style={{ backgroundColor: "transparent" }} elevation={0}>
      <AppDrawer open={open} toggleDrawer={toggleDrawer} />
      <Toolbar>
        <div style={{ flex: 1 }} />
        <div>
          <ElevationScroll {...props}>
            <Button
              edge="end"
              variant="contained"
              color="secondary"
              aria-label="menu"
              onClick={toggleDrawer}
            >
              <MenuIcon />
            </Button>
          </ElevationScroll>
        </div>
      </Toolbar>
    </AppBar>
    //
  );
}
