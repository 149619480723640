import React from "react";
import { useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";

import { contactOptions } from "../../../data";

export default function Contact() {
  const theme = useTheme();

  return (
    <Grid container justify="center">
      <Grid item xs={12}>
        <Grid container direction="row" justify="center">
          {contactOptions.map((link) => (
            <Button
              id={link.id}
              href={link.href}
              target="_blank"
              rel="noopener"
              aria-label={link.ariaLabel}
            >
              <Icon
                className={link.icon}
                style={{ color: theme.palette.text.light }}
              />
            </Button>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
