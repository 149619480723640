export const contactOptions = [
    {
      id: "linkedin-button",
      href: "https://www.linkedin.com/in/kstandell",
      ariaLabel: "linkedin",
      icon: "fab fa-linkedin",
    },
    {
      id: "stackoverflow-button",
      href: "https://stackoverflow.com/users/2702815/kstandell",
      ariaLabel: "stackoverflow",
      icon: "fab fa-stack-overflow",
    },
    {
      id: "github-button",
      href: "https://github.com/kstandell",
      ariaLabel: "github",
      icon: "fab fa-github",
    },
    {
        id: "instagram-button",
        href: "https://www.instagram.com/kstandell",
        ariaLabel: "instagram",
        icon: "fab fa-instagram",
      },
    {
      id: "email-button",
      href: "mailto://kris-standell@live.co.uk",
      ariaLabel: "email",
      icon: "far fa-envelope",
    },
  ];