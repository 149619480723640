export default function useScrollTo(anchorName) {
  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      anchorName
    );

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth" });
    }
  };

  return handleClick;
}
