import React from "react";
import { loadCSS } from "fg-loadcss";
import { Switch, Route, Redirect } from "react-router-dom";
import Fab from "@material-ui/core/Fab";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import { AppNavBar, ShowOnScroll } from "./components";
import { About, Skills, Experience, Interests } from "./features";

import { useScrollTo } from "./hooks";

function App(props) {
  React.useEffect(() => {
    loadCSS(
      "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
      document.querySelector("#font-awesome-css")
    );
  }, []);

  const handleClick = useScrollTo("#back-to-top-anchor");

  return (
    <div id="back-to-top-anchor" className="root">
      <Switch>
        <Route path="/">
          <AppNavBar {...props} />
          <About />
          <Skills />
          <Experience />
          <Interests />
        </Route>
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
      <ShowOnScroll threshold={100} handleClick={handleClick}>
        <Fab color="secondary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ShowOnScroll>
    </div>
  );
}

export default App;
