import moment from "moment";

export const education = [
  {
    name: "B.Sc Computer Science",
    institution: "University Of The West Of England",
    result: "First Class Honours",
    location: "Bristol",
    startDate: moment("2010-09-01"),
    endDate: moment("2013-06-01")
  },
  // {
  //   name: "BTEC Music Performance",
  //   institution: "Hereford College Of Arts",
  //   result: "Distinction",
  //   location: "Hereford",
  //   startDate: moment("2008-09-01"),
  //   endDate: moment("2010-06-01")
  // }
];
