import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Carousel from "react-material-ui-carousel";
import { withSection } from "../../../components";
import { getSkills } from "../../../data";
import Skill from "./Skill";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: 4,
  },
  category: {
    padding: theme.spacing(2),
  },
}));

function SkillSet(props) {
  const classes = useStyles();

  return (
    <Grid container justify="center" {...props}>
      {props.skills.map((skill) => (
        <Grid item xs={12} className={classes.category}>
          <Box
            fontSize={18}
            fontWeight="fontWeightMedium"
            textAlign="center"
            paddingTop={4}
          >
            {skill.label}
          </Box>
          <Grid container direction="row" justify="center">
            {skill.values.map((item) => (
              <Grid item sm={6} md={2}>
                <Skill skill={item} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}

function Skills() {
  const classes = useStyles();
  const theme = useTheme();
  const skills = getSkills(theme);

  return (
    <Grid
      container
      className={classes.root}
      justify="center"
      component={Carousel}
      navButtonsAlwaysVisible
    >
      <Grid
        item
        xs={12}
        component={SkillSet}
        skills={skills.filter((skill) => ["Languages"].includes(skill.label))}
      />
      <Grid
        item
        xs={12}
        component={SkillSet}
        skills={skills.filter((skill) =>
          ["Web/Mobile", "Databases"].includes(skill.label)
        )}
      />
      <Grid
        item
        xs={12}
        component={SkillSet}
        skills={skills.filter((skill) =>
          ["Infrastructure", "CI/CD"].includes(skill.label)
        )}
      />
      <Grid
        item
        xs={12}
        component={SkillSet}
        skills={skills.filter((skill) =>
          ["Cloud", "Build Tools"].includes(skill.label)
        )}
      />
      <Grid
        item
        xs={12}
        component={SkillSet}
        skills={skills.filter((skill) => ["Other"].includes(skill.label))}
      />
    </Grid>
  );
}

export default withSection("skills", "What I'm Familiar With", Skills);
