import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";

import { preferences } from "../../../data";

const useStyles = makeStyles((theme) => ({
  chips: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

export default function Preferences() {
  const classes = useStyles();
  return (
    <div id="preferences" className={classes.chips}>
      {preferences.map((preference) => (
        <Chip label={preference} color="secondary" className={classes.chip} />
      ))}
    </div>
  );
}
