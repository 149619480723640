import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import moment from "moment";

import { Contact } from ".";

export default function Profile() {

  const yearsSinceStarting = moment().diff(moment("2010-09-01"), "years");

  return (
    <div id="profile">
        <Avatar
          alt="Kris Standell - Software Developer"
          src={require("../../../assets/images/Kris-min.png")}
          style={{ width: 150, height: 150, margin: "auto", borderWidth: 1, borderColor: '#eee' }}
        />
        <Box textAlign="center" component="h1">
          Kris Standell
        </Box>
        <Box fontWeight="fontWeightMedium" textAlign="center" component="h2">
          Full Stack Software Engineer
        </Box>
        <Box fontWeight="fontWeightLight" textAlign="center">
          {yearsSinceStarting} years experience
        </Box>
        <Box
          fontSize={14}
          fontWeight="fontWeightLight"
          textAlign="center"
          paddingTop={2.25}
          paddingBottom={2.25}
        >
          <LocationOnIcon style={{ height: 14 }} />
          London, UK
        </Box>
        <Contact />
    </div>
  );
}
