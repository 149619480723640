import moment from "moment";

export function getAllJobs() {
  return [
    {
      company: "Clarke Software",
      startDate: moment("2013-06-01"),
      endDate: moment("2013-08-31"),
      location: "Wales",
      title: "Temporary Software Developer",
      description:
        "I joined this company in a temporary role straight after graduation to help them out with the workload they had. I worked on a few  small code changes in ASP VB before moving to London to join a graduate scheme.",
    },
    {
      company: "FDM Group",
      startDate: moment("2013-09-01"),
      endDate: moment("2016-01-31"),
      location: "London",
      title: "IT Consultant",
      team: "Java Engineer",
      description:
        "I joined a graduate scheme shortly after leaving university where I received 4 months in-depth training in Java and other useful technology skills such as SQL and bash scripting. This training gave me a solid foundation which I feel set me up with the right skills to progress to where I am now. I spent the majority of my time with this company place at British Airways.",
    },
    {
      company: "Avios Group",
      startDate: moment("2014-01-02"),
      endDate: moment("2014-04-01"),
      location: "London",
      title: "Software Engineer",
      team: "Flight Redemptions",
      description:
        "I was placed in this team while working for FDM as an IT consultant. I worked on the web service for flight redemptions using Avios points. This project was cut short due to Avios Group, a subsidiary of British Airways, being moved under IAG the parent company of BA.",
    },
    {
      company: "British Airways",
      startDate: moment("2014-04-02"),
      endDate: moment("2016-01-31"),
      location: "London",
      title: "Software Engineer",
      team: "New Distribution Capability",
      description:
        "I was placed at British Airways while working for FDM. Here I worked on their New Distribution Capability (NDC) programme which involved the implementation of a new suite of services in line with the standards defined by IATA. Given this was the very beginning of NDC I got to play a big part in delivering some of the key services such as AirShopping, FlightPrice and OrderCreate. The first use of these services was seen on SkyScanner where you are now able to purchase tickets directly through their site without the need to visit ba.com.",
    },
    {
      company: "Redwood Software",
      startDate: moment("2016-02-01"),
      endDate: moment("2017-08-30"),
      location: "London",
      title: "Java Developer",
      team: "Finance Automation",
      description:
        "During my time at Redwood I worked with a great team of engineers on a number of different parts of their finance automation offering. My main focus was on delivering an automated solution to Balance Sheet Reconciliation and Certification which had integrations with SAP and Oracle.",
    },

    {
      company: "Marks & Spencer",
      startDate: moment("2017-09-01"),
      endDate: moment("2019-06-01"),
      location: "London",
      title: "Software Engineer",
      team: "Core APIs",
      description:
        "During my time in this team I delivered a variety of projects. Two of which I played a large part in delivering including a new initiative, called Mobile Pay Go, where I worked along side our third party POS provider and mobile team, to deliver a new shopping experience to our customers and a new carrier gateway replacing our existing third party solution. I built out a new single interface to multiple carriers such as Royal Mail, UK Mail and DPD.",
    },
    {
      company: "Marks & Spencer",
      startDate: moment("2019-06-01"),
      endDate: moment("2020-04-01"),
      location: "London",
      title:
        "Software Engineer",
        team: "Try Tuesday",
      description:
        "This was a transition to a new language for me (Python) which was an exciting new challenge. Myself and a small new team were building out an outfit and product recommendation service for M&S. My role was to build and maintain an outfit recommendation service which I owned throughout the life of Tuesday. I also built a stylist service, a new auth service and a back office styling application in React. The services I worked on here followed an event driven architecture. I had also made substantial progress in building a Try-Tuesday mobile app in React Native but due to M&S making major cuts in funding the Tuesday brand was closed down before that was able to be released.",
    },
    {
      company: "Marks & Spencer",
      startDate: moment("2020-04-01"),
      endDate: moment(),
      location: "London",
      title: "Software Engineer",
      team: "FIND",
      description:
        "I have recently moved into this new team, which is responsible for Search capabilities on the .com site. So far, I have suggested building a BDD style test framework so we have clearer visibility of the requirements and confidence in our testing. I have also identified some notable areas of tech debt to address.",
    },
  ];
}
