import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    flex: 1,
    justifyContent: "center",
    alignContent: "center",
  },
}));

const withSection = (name, title, Children) => (props) => {
  const classes = useStyles();
  return (
    <Grid
      container
      id={name}
      className={classes.root}
      alignItems="center"
      spacing={4}
    >
      <Grid item xs={12} justify="center" style={{ display: "flex" }}>
        <Box fontSize={24} fontWeight="bold" component="h2">
          {title}
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Children {...props} />
      </Grid>
    </Grid>
  );
};

export default withSection;
