import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export function getInterests(theme) {
  const colour = theme.palette.secondary.light;
  return [
    {
      name: "Cooking",
      description:
        "Big lover of good food. Who isn't though. I came to really enjoy this after subscribing to HelloFresh and receiving fresh meals to cook every week.",
      icon: (
        <SvgIcon>
          <path
            fill={colour}
            d="M15.661 15.549a1.315 1.315 0 0 0 1.185-1.386a1.363 1.363 0 0 0-1.35-1.302h-.048a1.352 1.352 0 0 0-1.303 1.397c.024.379.179.687.391.911c-.827 1.609-2.07 2.794-3.954 3.788c-1.266.663-2.604.912-3.905.734c-1.089-.153-1.94-.64-2.463-1.421c-.78-1.185-.852-2.462-.201-3.74a5.597 5.597 0 0 1 1.658-1.931a7.88 7.88 0 0 1-.331-1.218c-3.506 2.51-3.148 5.942-2.084 7.564c.794 1.184 2.415 1.941 4.19 1.941c.474 0 .972-.035 1.457-.154c3.077-.592 5.409-2.438 6.747-5.16l.011-.023z"
          />
          <path
            fill={colour}
            d="M19.887 12.589c-1.834-2.154-4.533-3.337-7.611-3.337h-.403c-.2-.438-.661-.711-1.183-.711h-.036c-.744 0-1.325.64-1.301 1.385c.023.71.627 1.302 1.35 1.302h.059a1.332 1.332 0 0 0 1.183-.828h.439c1.824 0 3.551.532 5.126 1.574c1.206.792 2.072 1.834 2.557 3.077c.425 1.019.402 2.013-.035 2.843c-.675 1.302-1.812 1.988-3.314 1.988c-.947 0-1.871-.296-2.345-.509c-.283.235-.758.626-1.102.863c1.042.473 2.096.746 3.113.746c2.309 0 4.023-1.302 4.676-2.557c.709-1.422.651-3.813-1.161-5.859l-.012.023z"
          />
          <path
            fill={colour}
            d="M7.647 15.975c.023.71.626 1.302 1.35 1.302h.048a1.334 1.334 0 0 0 1.302-1.397c0-.71-.616-1.301-1.338-1.301h-.048c-.048 0-.118 0-.178.022c-.982-1.657-1.397-3.434-1.242-5.349c.094-1.445.567-2.7 1.42-3.742c.71-.888 2.048-1.326 2.96-1.35c2.556-.048 3.622 3.138 3.704 4.404l1.184.354C16.536 5.036 14.122 3 11.813 3C9.647 3 7.647 4.574 6.842 6.884c-1.102 3.077-.379 6.036.971 8.404c-.118.154-.189.426-.166.687z"
          />
        </SvgIcon>
      ),
    },
    {
      name: "Guitar",
      description:
        "This is probably my old and longest love affair. I began playing guitar when I was just five years old and haven't stopped since. I completed all of the grades during my time at school and have played in a number of bands over the years.",
      icon: (
        <SvgIcon>
          <path
            fill={colour}
            d="M15.661 15.549a1.315 1.315 0 0 0 1.185-1.386a1.363 1.363 0 0 0-1.35-1.302h-.048a1.352 1.352 0 0 0-1.303 1.397c.024.379.179.687.391.911c-.827 1.609-2.07 2.794-3.954 3.788c-1.266.663-2.604.912-3.905.734c-1.089-.153-1.94-.64-2.463-1.421c-.78-1.185-.852-2.462-.201-3.74a5.597 5.597 0 0 1 1.658-1.931a7.88 7.88 0 0 1-.331-1.218c-3.506 2.51-3.148 5.942-2.084 7.564c.794 1.184 2.415 1.941 4.19 1.941c.474 0 .972-.035 1.457-.154c3.077-.592 5.409-2.438 6.747-5.16l.011-.023z"
          />
          <path
            fill={colour}
            d="M19.887 12.589c-1.834-2.154-4.533-3.337-7.611-3.337h-.403c-.2-.438-.661-.711-1.183-.711h-.036c-.744 0-1.325.64-1.301 1.385c.023.71.627 1.302 1.35 1.302h.059a1.332 1.332 0 0 0 1.183-.828h.439c1.824 0 3.551.532 5.126 1.574c1.206.792 2.072 1.834 2.557 3.077c.425 1.019.402 2.013-.035 2.843c-.675 1.302-1.812 1.988-3.314 1.988c-.947 0-1.871-.296-2.345-.509c-.283.235-.758.626-1.102.863c1.042.473 2.096.746 3.113.746c2.309 0 4.023-1.302 4.676-2.557c.709-1.422.651-3.813-1.161-5.859l-.012.023z"
          />
          <path
            fill={colour}
            d="M7.647 15.975c.023.71.626 1.302 1.35 1.302h.048a1.334 1.334 0 0 0 1.302-1.397c0-.71-.616-1.301-1.338-1.301h-.048c-.048 0-.118 0-.178.022c-.982-1.657-1.397-3.434-1.242-5.349c.094-1.445.567-2.7 1.42-3.742c.71-.888 2.048-1.326 2.96-1.35c2.556-.048 3.622 3.138 3.704 4.404l1.184.354C16.536 5.036 14.122 3 11.813 3C9.647 3 7.647 4.574 6.842 6.884c-1.102 3.077-.379 6.036.971 8.404c-.118.154-.189.426-.166.687z"
          />
        </SvgIcon>
      ),
    },
    {
      name: "Gaming",
      description:
        "I'm still a child at heart and regularly find myself playing on the PlayStation. My first game was Sonic on the Mega Drive and have loved gaming ever since.",
      icon: (
        <SvgIcon>
          <path
            fill={colour}
            d="M15.661 15.549a1.315 1.315 0 0 0 1.185-1.386a1.363 1.363 0 0 0-1.35-1.302h-.048a1.352 1.352 0 0 0-1.303 1.397c.024.379.179.687.391.911c-.827 1.609-2.07 2.794-3.954 3.788c-1.266.663-2.604.912-3.905.734c-1.089-.153-1.94-.64-2.463-1.421c-.78-1.185-.852-2.462-.201-3.74a5.597 5.597 0 0 1 1.658-1.931a7.88 7.88 0 0 1-.331-1.218c-3.506 2.51-3.148 5.942-2.084 7.564c.794 1.184 2.415 1.941 4.19 1.941c.474 0 .972-.035 1.457-.154c3.077-.592 5.409-2.438 6.747-5.16l.011-.023z"
          />
          <path
            fill={colour}
            d="M19.887 12.589c-1.834-2.154-4.533-3.337-7.611-3.337h-.403c-.2-.438-.661-.711-1.183-.711h-.036c-.744 0-1.325.64-1.301 1.385c.023.71.627 1.302 1.35 1.302h.059a1.332 1.332 0 0 0 1.183-.828h.439c1.824 0 3.551.532 5.126 1.574c1.206.792 2.072 1.834 2.557 3.077c.425 1.019.402 2.013-.035 2.843c-.675 1.302-1.812 1.988-3.314 1.988c-.947 0-1.871-.296-2.345-.509c-.283.235-.758.626-1.102.863c1.042.473 2.096.746 3.113.746c2.309 0 4.023-1.302 4.676-2.557c.709-1.422.651-3.813-1.161-5.859l-.012.023z"
          />
          <path
            fill={colour}
            d="M7.647 15.975c.023.71.626 1.302 1.35 1.302h.048a1.334 1.334 0 0 0 1.302-1.397c0-.71-.616-1.301-1.338-1.301h-.048c-.048 0-.118 0-.178.022c-.982-1.657-1.397-3.434-1.242-5.349c.094-1.445.567-2.7 1.42-3.742c.71-.888 2.048-1.326 2.96-1.35c2.556-.048 3.622 3.138 3.704 4.404l1.184.354C16.536 5.036 14.122 3 11.813 3C9.647 3 7.647 4.574 6.842 6.884c-1.102 3.077-.379 6.036.971 8.404c-.118.154-.189.426-.166.687z"
          />
        </SvgIcon>
      ),
    },
    {
      name: "Travelling",
      description:
        "Can't stay still. I want to see as much of the world as I can. My favourite places to date have been Japan, Iceland and America.",
      icon: (
        <SvgIcon>
          <path
            fill={colour}
            d="M15.661 15.549a1.315 1.315 0 0 0 1.185-1.386a1.363 1.363 0 0 0-1.35-1.302h-.048a1.352 1.352 0 0 0-1.303 1.397c.024.379.179.687.391.911c-.827 1.609-2.07 2.794-3.954 3.788c-1.266.663-2.604.912-3.905.734c-1.089-.153-1.94-.64-2.463-1.421c-.78-1.185-.852-2.462-.201-3.74a5.597 5.597 0 0 1 1.658-1.931a7.88 7.88 0 0 1-.331-1.218c-3.506 2.51-3.148 5.942-2.084 7.564c.794 1.184 2.415 1.941 4.19 1.941c.474 0 .972-.035 1.457-.154c3.077-.592 5.409-2.438 6.747-5.16l.011-.023z"
          />
          <path
            fill={colour}
            d="M19.887 12.589c-1.834-2.154-4.533-3.337-7.611-3.337h-.403c-.2-.438-.661-.711-1.183-.711h-.036c-.744 0-1.325.64-1.301 1.385c.023.71.627 1.302 1.35 1.302h.059a1.332 1.332 0 0 0 1.183-.828h.439c1.824 0 3.551.532 5.126 1.574c1.206.792 2.072 1.834 2.557 3.077c.425 1.019.402 2.013-.035 2.843c-.675 1.302-1.812 1.988-3.314 1.988c-.947 0-1.871-.296-2.345-.509c-.283.235-.758.626-1.102.863c1.042.473 2.096.746 3.113.746c2.309 0 4.023-1.302 4.676-2.557c.709-1.422.651-3.813-1.161-5.859l-.012.023z"
          />
          <path
            fill={colour}
            d="M7.647 15.975c.023.71.626 1.302 1.35 1.302h.048a1.334 1.334 0 0 0 1.302-1.397c0-.71-.616-1.301-1.338-1.301h-.048c-.048 0-.118 0-.178.022c-.982-1.657-1.397-3.434-1.242-5.349c.094-1.445.567-2.7 1.42-3.742c.71-.888 2.048-1.326 2.96-1.35c2.556-.048 3.622 3.138 3.704 4.404l1.184.354C16.536 5.036 14.122 3 11.813 3C9.647 3 7.647 4.574 6.842 6.884c-1.102 3.077-.379 6.036.971 8.404c-.118.154-.189.426-.166.687z"
          />
        </SvgIcon>
      ),
    },
    {
      name: "Keeping Fit",
      description:
        "I've been going to the gym and trying to keep fit since I left school. I think it's important to look after yourself (especially with my love of food).",

      icon: (
        <SvgIcon>
          <path
            fill={colour}
            d="M15.661 15.549a1.315 1.315 0 0 0 1.185-1.386a1.363 1.363 0 0 0-1.35-1.302h-.048a1.352 1.352 0 0 0-1.303 1.397c.024.379.179.687.391.911c-.827 1.609-2.07 2.794-3.954 3.788c-1.266.663-2.604.912-3.905.734c-1.089-.153-1.94-.64-2.463-1.421c-.78-1.185-.852-2.462-.201-3.74a5.597 5.597 0 0 1 1.658-1.931a7.88 7.88 0 0 1-.331-1.218c-3.506 2.51-3.148 5.942-2.084 7.564c.794 1.184 2.415 1.941 4.19 1.941c.474 0 .972-.035 1.457-.154c3.077-.592 5.409-2.438 6.747-5.16l.011-.023z"
          />
          <path
            fill={colour}
            d="M19.887 12.589c-1.834-2.154-4.533-3.337-7.611-3.337h-.403c-.2-.438-.661-.711-1.183-.711h-.036c-.744 0-1.325.64-1.301 1.385c.023.71.627 1.302 1.35 1.302h.059a1.332 1.332 0 0 0 1.183-.828h.439c1.824 0 3.551.532 5.126 1.574c1.206.792 2.072 1.834 2.557 3.077c.425 1.019.402 2.013-.035 2.843c-.675 1.302-1.812 1.988-3.314 1.988c-.947 0-1.871-.296-2.345-.509c-.283.235-.758.626-1.102.863c1.042.473 2.096.746 3.113.746c2.309 0 4.023-1.302 4.676-2.557c.709-1.422.651-3.813-1.161-5.859l-.012.023z"
          />
          <path
            fill={colour}
            d="M7.647 15.975c.023.71.626 1.302 1.35 1.302h.048a1.334 1.334 0 0 0 1.302-1.397c0-.71-.616-1.301-1.338-1.301h-.048c-.048 0-.118 0-.178.022c-.982-1.657-1.397-3.434-1.242-5.349c.094-1.445.567-2.7 1.42-3.742c.71-.888 2.048-1.326 2.96-1.35c2.556-.048 3.622 3.138 3.704 4.404l1.184.354C16.536 5.036 14.122 3 11.813 3C9.647 3 7.647 4.574 6.842 6.884c-1.102 3.077-.379 6.036.971 8.404c-.118.154-.189.426-.166.687z"
          />
        </SvgIcon>
      ),
    },

    {
      name: "Flying",
      description:
        "I've always wanted to be a pilot. Would love to get my private pilots license. Sadly, it's quite an expensive venture so may have to wait.",
      icon: (
        <SvgIcon>
          <path
            fill={colour}
            d="M15.661 15.549a1.315 1.315 0 0 0 1.185-1.386a1.363 1.363 0 0 0-1.35-1.302h-.048a1.352 1.352 0 0 0-1.303 1.397c.024.379.179.687.391.911c-.827 1.609-2.07 2.794-3.954 3.788c-1.266.663-2.604.912-3.905.734c-1.089-.153-1.94-.64-2.463-1.421c-.78-1.185-.852-2.462-.201-3.74a5.597 5.597 0 0 1 1.658-1.931a7.88 7.88 0 0 1-.331-1.218c-3.506 2.51-3.148 5.942-2.084 7.564c.794 1.184 2.415 1.941 4.19 1.941c.474 0 .972-.035 1.457-.154c3.077-.592 5.409-2.438 6.747-5.16l.011-.023z"
          />
          <path
            fill={colour}
            d="M19.887 12.589c-1.834-2.154-4.533-3.337-7.611-3.337h-.403c-.2-.438-.661-.711-1.183-.711h-.036c-.744 0-1.325.64-1.301 1.385c.023.71.627 1.302 1.35 1.302h.059a1.332 1.332 0 0 0 1.183-.828h.439c1.824 0 3.551.532 5.126 1.574c1.206.792 2.072 1.834 2.557 3.077c.425 1.019.402 2.013-.035 2.843c-.675 1.302-1.812 1.988-3.314 1.988c-.947 0-1.871-.296-2.345-.509c-.283.235-.758.626-1.102.863c1.042.473 2.096.746 3.113.746c2.309 0 4.023-1.302 4.676-2.557c.709-1.422.651-3.813-1.161-5.859l-.012.023z"
          />
          <path
            fill={colour}
            d="M7.647 15.975c.023.71.626 1.302 1.35 1.302h.048a1.334 1.334 0 0 0 1.302-1.397c0-.71-.616-1.301-1.338-1.301h-.048c-.048 0-.118 0-.178.022c-.982-1.657-1.397-3.434-1.242-5.349c.094-1.445.567-2.7 1.42-3.742c.71-.888 2.048-1.326 2.96-1.35c2.556-.048 3.622 3.138 3.704 4.404l1.184.354C16.536 5.036 14.122 3 11.813 3C9.647 3 7.647 4.574 6.842 6.884c-1.102 3.077-.379 6.036.971 8.404c-.118.154-.189.426-.166.687z"
          />
        </SvgIcon>
      ),
    },
    {
      name: "Investing",
      description:
        "I have been investing in various stocks & shares over the years. Always looking for ways to grow my money.",
      icon: (
        <SvgIcon>
          <path
            fill={colour}
            d="M15.661 15.549a1.315 1.315 0 0 0 1.185-1.386a1.363 1.363 0 0 0-1.35-1.302h-.048a1.352 1.352 0 0 0-1.303 1.397c.024.379.179.687.391.911c-.827 1.609-2.07 2.794-3.954 3.788c-1.266.663-2.604.912-3.905.734c-1.089-.153-1.94-.64-2.463-1.421c-.78-1.185-.852-2.462-.201-3.74a5.597 5.597 0 0 1 1.658-1.931a7.88 7.88 0 0 1-.331-1.218c-3.506 2.51-3.148 5.942-2.084 7.564c.794 1.184 2.415 1.941 4.19 1.941c.474 0 .972-.035 1.457-.154c3.077-.592 5.409-2.438 6.747-5.16l.011-.023z"
          />
          <path
            fill={colour}
            d="M19.887 12.589c-1.834-2.154-4.533-3.337-7.611-3.337h-.403c-.2-.438-.661-.711-1.183-.711h-.036c-.744 0-1.325.64-1.301 1.385c.023.71.627 1.302 1.35 1.302h.059a1.332 1.332 0 0 0 1.183-.828h.439c1.824 0 3.551.532 5.126 1.574c1.206.792 2.072 1.834 2.557 3.077c.425 1.019.402 2.013-.035 2.843c-.675 1.302-1.812 1.988-3.314 1.988c-.947 0-1.871-.296-2.345-.509c-.283.235-.758.626-1.102.863c1.042.473 2.096.746 3.113.746c2.309 0 4.023-1.302 4.676-2.557c.709-1.422.651-3.813-1.161-5.859l-.012.023z"
          />
          <path
            fill={colour}
            d="M7.647 15.975c.023.71.626 1.302 1.35 1.302h.048a1.334 1.334 0 0 0 1.302-1.397c0-.71-.616-1.301-1.338-1.301h-.048c-.048 0-.118 0-.178.022c-.982-1.657-1.397-3.434-1.242-5.349c.094-1.445.567-2.7 1.42-3.742c.71-.888 2.048-1.326 2.96-1.35c2.556-.048 3.622 3.138 3.704 4.404l1.184.354C16.536 5.036 14.122 3 11.813 3C9.647 3 7.647 4.574 6.842 6.884c-1.102 3.077-.379 6.036.971 8.404c-.118.154-.189.426-.166.687z"
          />
        </SvgIcon>
      ),
    },
  ];
}
