import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import moment from "moment";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import DateRangeIcon from "@material-ui/icons/DateRange";
import WorkIcon from "@material-ui/icons/Work";
import LocalLibraryIcon from "@material-ui/icons/LocalLibrary";
import { Timeline, TimelineItem } from "vertical-timeline-component-for-react";

import { withSection } from "../../../components";
import { getAllJobs, education } from "../../../data";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    marginTop: 0,
    marginBottom: 0,
    justifyContent: 'center'
  },
  date: {
    marginLeft: 35,
    marginRight: 35,
    padding: theme.spacing(1),
    background: theme.palette.secondary.main,
    color: theme.palette.text.light,
  },
  job: {
    marginTop: theme.spacing(6),
    padding: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    textTransform: "none",
  },
}));

function sortByStartDate(job1, job2) {
  if (job1.startDate > job2.startDate) {
    return -1;
  }
  if (job2.startDate > job1.startDate) {
    return 1;
  }
  return 0;
}

function TimelineDate({ name, location, startDate, endDate, type }) {
  const classes = useStyles();
  const dateFormat = "MMM 'YY";
  return (
    <Paper className={classes.date}>
      <Box fontSize={14} fontWeight="bold">
        {type === "JOB" ? (
          <WorkIcon style={{ height: 14 }} />
        ) : (
          <LocalLibraryIcon style={{ height: 14 }} />
        )}
        {name}
      </Box>
      <Box fontSize={12} fontWeight="fontWeightLight">
        <DateRangeIcon style={{ height: 14 }} />
        {startDate.format(dateFormat) +
          " to " +
          (endDate.diff(moment(), "days") === 0
            ? "Present"
            : endDate.format(dateFormat))}
      </Box>
      <Box fontSize={12} fontWeight="fontWeightLight" alignItems="right">
        <LocationOnIcon style={{ height: 14 }} />
        {location}
      </Box>
    </Paper>
  );
}

function TimelineEvent({
  name,
  location,
  startDate,
  endDate,
  type,
  title,
  subTitle,
  description,
}) {
  const classes = useStyles();

  return (
    <TimelineItem
      key={name}
      dateComponent={
        <TimelineDate
          name={name}
          location={location}
          startDate={startDate}
          endDate={endDate}
          type={type}
        />
      }
    >
      <Paper className={classes.job}>
        <Box fontSize={16} fontWeight="bold">
          {title}
        </Box>
        <Box fontSize={14} fontWeight="fontWeightMedium">
          {subTitle}
        </Box>
        <Box fontSize={14} fontWeight="fontWeightLight" paddingTop={2}>
          {description}
        </Box>
      </Paper>
    </TimelineItem>
  );
}

function Experience() {
  const classes = useStyles();
  const theme = useTheme();
  const jobs = getAllJobs().sort(sortByStartDate);
  const allEducation = education.sort(sortByStartDate);

  return (
    <Timeline className={classes.root} lineColor={theme.palette.primary.light}>
      {jobs.map((job, index) => (
        <TimelineEvent
          name={job.company}
          location={job.location}
          startDate={job.startDate}
          endDate={job.endDate}
          title={job.title}
          subTitle={job.team}
          description={job.description}
          type="JOB"
        />
      ))}
      {allEducation.map((course, index) => (
        <TimelineEvent
          name={course.institution}
          location={course.location}
          startDate={course.startDate}
          endDate={course.endDate}
          title={course.name}
          subTitle={course.result}
          type="COURSE"
        />
      ))}
    </Timeline>
  );
}

export default withSection("experience", "The Journey So Far...", Experience);
