import React from "react";
import useScrollTrigger from '@material-ui/core/useScrollTrigger';

export default function ElevationScroll(props) {
    const { children } = props;
    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 50
    });
  
    return React.cloneElement(children, {
      elevation: trigger ? 4 : 0,
    });
  }