import React, { useRef } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";

import { withSection } from "../../../components";
import { getInterests } from "../../../data";
import { useOnScreen } from "../../../hooks";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  name: {
    margin: theme.spacing(2),
    color: theme.palette.text.light,
  },
  interest: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.secondary.main,
  },
}));

function Interests(props) {
  const classes = useStyles();
  const theme = useTheme();

  const ref = useRef();
  const onScreen = useOnScreen(ref, "-50px");

  return (
    <Grid ref={ref} container spacing={2} justify="center">
      {getInterests(theme).map((interest) => (
        <Grow in={onScreen}>
          <Grid item xs={12} sm={6} md={3}>
            <Paper className={classes.interest}>
              <Box
                fontSize={18}
                fontWeight="bold"
                color={theme.palette.text.light}
                paddingBottom={1}
                textAlign="center"
              >
                {interest.name}
              </Box>
              <Box
                fontSize={16}
                fontWeight="fontWeightMedium"
                color={theme.palette.text.light}
                textAlign="center"
              >
                {interest.description}
              </Box>
            </Paper>
          </Grid>
        </Grow>
      ))}
    </Grid>
  );
}

export default withSection("interests", "What Interests Me", Interests);
