import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
}));

export default function Introduction() {
  const classes = useStyles();

  return (
    <div id="introduction" className={classes.root}>
      <Box
        fontSize={18}
        fontWeight="fontWeightLight"
        textAlign="center"
        paddingTop={2}
        paddingBottom={2}
      >
        Advocate of Contract First, Behaviour Driven Development and Domain
        Driven Design.
      </Box>
      <Box
        fontSize={14}
        fontWeight="fontWeightLight"
        textAlign="center"
        paddingBottom={2}
      >
        I feel these concepts when combined in software development give way to
        clean, high quality code.
      </Box>
      <Box
        fontSize={14}
        fontWeight="fontWeightLight"
        textAlign="center"
        paddingBottom={2}
      >
        I'm a firm believer that code should be test driven and continuously
        refactored. To me an effective team is a small, close-knit one with a high degree of autonomy and trust.
      </Box>
      <Box fontSize={14} fontWeight="fontWeightLight" textAlign="center">
        My real strength is in Java but I have commercial experience with Python
        and React + React Native with TypeScript and JavaScript as well. I also
        spend a reasonable amount of time on personal projects, with an interest
        in Mobile development. Throughout my career I have gained valuable
        experience decomposing monoliths and building micro-services.
      </Box>
      <Box
        fontSize={14}
        fontWeight="fontWeightLight"
        textAlign="center"
        paddingTop={2}
        paddingBottom={2}
      >
        I'm a fan of Event Sourcing and Event Driven Architectures.
      </Box>
    </div>
  );
}
