import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import { Introduction, Preferences, Profile } from ".";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.light,
  },
}));

export default function About() {
  const classes = useStyles();

  return (
    <Grid container id="about" className={classes.root}>
      <Grid item xs={12} component={Profile} />
      <Grid item xs={12} component={Introduction} />
      <Grid item xs={12} component={Preferences} />
    </Grid>
  );
}
