import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import { useScrollTo } from "../hooks";

const useStyles = makeStyles((theme) => ({
  list: {
    width: "auto",
    backgroundColor: theme.palette.primary.main,
  },
  button: {
    color: theme.palette.text.light,
    width: "100%",
  },
}));

export default function AppDrawer({ open, toggleDrawer }) {
  const classes = useStyles();

  return (
    <Drawer anchor="top" open={open} onClose={toggleDrawer}>
      <div
        className={classes.list}
        role="presentation"
        onClick={toggleDrawer}
        onKeyDown={toggleDrawer}
      >
        <List>
          <ListItem button key="about">
            <Button
              className={classes.button}
              color="secondary"
              href="#about"
            >
              An Introduction
            </Button>
          </ListItem>
          <ListItem button key="skills">
            <Button
              className={classes.button}
              color="secondary"
              href="#skills"
            >
              Tech I've Worked With
            </Button>
          </ListItem>
          <ListItem button key="experience">
            <Button
              className={classes.button}
              color="secondary"
              href="#experience"
            >
              My Journey So Far
            </Button>
          </ListItem>
          <ListItem button key="interests">
            <Button
              className={classes.button}
              color="secondary"
              href="#interests"
            >
              Things That Interest Me
            </Button>
          </ListItem>
        </List>
      </div>
    </Drawer>
  );
}
