import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
    padding: theme.spacing(4),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default function Skill({ skill }) {
  const classes = useStyles();
  return (
    <Paper className={classes.root}>
      {skill.icon}

      <Box
        fontSize={14}
        fontWeight="fontWeightLight"
        color="black"
        paddingLeft={2}
        textAlign="center"
      >
        {skill.name}
      </Box>
    </Paper>
  );
}
